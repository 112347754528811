import React, { Component } from 'react';
import Preview from '../Preview/index.js'
import { withRouter } from 'react-router-dom';

import {
  useTheme,
  createMuiTheme,
  ThemeProvider
} from "@mui/material/styles";

import '../../fonts/homemade.css';
import Typography from '@mui/material/Typography';
import { Table, Row, Cell } from 'react-responsive-table';

import Footer from '../Footer/index.js';


const theme = createMuiTheme({
  palette: {
    primary:{
      main: '#9F2914'
    }
  }
});

theme.typography.h3 = {
  color: '#9F2914',
  fontFamily: 'Homemade Apple',
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};

theme.typography.h6 = {
  color: '#FFFFFF',
  fontFamily: 'Roboto',
  fontSize: '0.6rem',
  '@media (min-width:100px)': {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.1rem',
  },
};

export class About extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }


  render() {
    return (
      <div>
        <div style={{textAlign:'center'}}>
          <ThemeProvider theme={theme}>
            <Typography variant="h3">¿Quienes Somos?</Typography>
          </ThemeProvider>
        </div>
        <div style={{border: 'white 2px solid',margin: '30px'}}>
        <Table>
            <Row>
              <Cell thead minWidthPx={100}>
              <ThemeProvider theme={theme}>
                  <Typography variant="h6" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#0C0C0C',fontSize: '1.0rem',
                  '@media (min-width:300px)': {
                    fontSize: '1.2rem',
                  },
                  [theme.breakpoints.up('md')]: {
                    fontSize: '2.0rem',
                  }}}>Avícola Lescano, empresa especializada en la producción de huevos, inició sus actividades en el año 1960 en la ciudad de Contumazá (Cajamarca) bajo la dirección de su fundador Alberto González Lescano, hoy accionista principal y Gerente General de la empresa. Según manifiesta el señor Gonzáles, fue su primo hermano Marciano Lescano Alforo, fue quién le dio la idea de criar gallinas y vender huevos.
                </Typography>
                </ThemeProvider>
                <br/>
                <ThemeProvider theme={theme}>
                  <Typography variant="h6" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#0C0C0C',fontSize: '1.0rem',
                  '@media (min-width:300px)': {
                    fontSize: '1.2rem',
                  },
                  [theme.breakpoints.up('md')]: {
                    fontSize: '2.0rem',
                  }}}>En su primer intento de avicultor, obtiene una producción de 33 huevos diarios contando con una incubadora prestada, la poca producción se comercializaba en la misma localidad, pero, posteriormente al crecer la oferta, se crea la necesidad de comercializar en la Hacienda Casa Grande (Trujillo). El mercado local en Contumazá era muy limitado dado que las familias por tradición criaban sus propias gallinas.
                  </Typography>
                </ThemeProvider>
                <br/>
              </Cell>
              <Cell thead minWidthPx={100}>
              </Cell>
            </Row>
            <Row>
              <Cell minWidthPx={100}></Cell>
              <Cell minWidthPx={100}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h6" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#0C0C0C',fontSize: '1.0rem',
                  '@media (min-width:300px)': {
                    fontSize: '1.2rem',
                  },
                  [theme.breakpoints.up('md')]: {
                    fontSize: '2.0rem',
                  }}}>Como todo empresario con visión de crecimiento, en 1962 se traslada al pueblo de Chicama, en esta localidad se constituye en un lugar estratégico por estar cercano al mercado de clientes e insumos, para lo cual adquiere sus primeras 100 aves ponedoras de la raza RODE ISLAND.
                  </Typography>
                  </ThemeProvider>
                  <br/>
                  <ThemeProvider theme={theme}>
                  <Typography variant="h6" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#0C0C0C',fontSize: '1.0rem',
                  '@media (min-width:300px)': {
                    fontSize: '1.2rem',
                  },
                  [theme.breakpoints.up('md')]: {
                    fontSize: '2.0rem',
                  }}}>También incursiona en la producción de pollos de carne y los comercializa en la ciudad de Trujillo, coincidiendo con la aparición del consumo de pollos a la brasa.
                  </Typography>
                  </ThemeProvider>
                  <br/>
                  <ThemeProvider theme={theme}>
                  <Typography variant="h6" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#0C0C0C',fontSize: '1.0rem',
                  '@media (min-width:300px)': {
                    fontSize: '1.2rem',
                  },
                  [theme.breakpoints.up('md')]: {
                    fontSize: '2.0rem',
                  }}}>Como era de esperar se introduce.
                  </Typography>
                  </ThemeProvider>
                  <br/>
                </Cell>
            </Row>
            <Row>
              <Cell minWidthPx={100}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h6" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#0C0C0C',fontSize: '1.0rem',
                  '@media (min-width:300px)': {
                    fontSize: '1.2rem',
                  },
                  [theme.breakpoints.up('md')]: {
                    fontSize: '2.0rem',
                  }}}>La infraestructura de galpones y jaulas tuvo una evolución desde las jaulas de cajón hasta las jaulas semiautomáticas en este proceso Alberto Gonzáles se nutrió de consejos, documentos, revistas, viajes, y gracias a su ingenio y creatividad diseño su infraestructura a medida de sus necesidades, lo que significa con su propia marca de diseño.
                  </Typography>
                </ThemeProvider>
                <br/>
                <ThemeProvider theme={theme}>
                  <Typography variant="h6" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#0C0C0C',fontSize: '1.0rem',
                  '@media (min-width:300px)': {
                    fontSize: '1.2rem',
                  },
                  [theme.breakpoints.up('md')]: {
                    fontSize: '2.0rem',
                  }}}>Desde el inicio de este proyecto empresarial, la familia fue un soporte, su esposa Rosa Lescano Pretel, se constituyó, hasta la fecha en su brazo derecho.
                  </Typography>
                </ThemeProvider>
                <br/>
              </Cell>
              <Cell minWidthPx={100}></Cell>
            </Row>
            <Row>
              <Cell minWidthPx={100}></Cell>
              <Cell minWidthPx={100}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h6" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#0C0C0C',fontSize: '1.0rem',
                  '@media (min-width:300px)': {
                    fontSize: '1.2rem',
                  },
                  [theme.breakpoints.up('md')]: {
                    fontSize: '2.0rem',
                  }}}>El año 1992 la familia sufre la temprana desaparición del hijo mayor, Luis Alberto Gonzáles Lescano (abril 1992), quien ya venía haciéndose cargo de la dirección de la empresa, situación que obligo al fundador a retomar dicha dirección hasta la fecha.
                  </Typography>
                </ThemeProvider>
                <br/>
                <ThemeProvider theme={theme}>
                  <Typography variant="h6" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#0C0C0C',fontSize: '1.0rem',
                  '@media (min-width:300px)': {
                    fontSize: '1.2rem',
                  },
                  [theme.breakpoints.up('md')]: {
                    fontSize: '2.0rem',
                  }}}>En el año 1975 la empresa decide dar un paso más adelante e implementar su propia planta de alimentos balanceados y en 1980 adquiere un nuevo terreno para ampliar su producción.
                  </Typography>
                </ThemeProvider>
                <br/>
                <ThemeProvider theme={theme}>
                  <Typography variant="h6" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#0C0C0C',fontSize: '1.0rem',
                  '@media (min-width:300px)': {
                    fontSize: '1.2rem',
                  },
                  [theme.breakpoints.up('md')]: {
                    fontSize: '2.0rem',
                  }}}>A inicios de la actual década se constituye la Asociación de Productores de Huevos del Norte(APROHOR), donde Alberto Gonzáles fue su primer presidente.
                  </Typography>
                </ThemeProvider>
                <br/>
              </Cell>
            </Row>
            <Row>
              <Cell minWidthPx={100}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h6" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#0C0C0C',fontSize: '1.0rem',
                  '@media (min-width:300px)': {
                    fontSize: '1.2rem',
                  },
                  [theme.breakpoints.up('md')]: {
                    fontSize: '2.0rem',
                  }}}>Hoy al cabo de 50 años de mucho sacrificio, dedicación, disciplina, responsabilidad social y amor por la familia, la empresa tiene aproximadamente medio millón de a veces, 260 trabajadores, una infraestructura moderna de producción de huevos y alimentos balanceados, siendo la empresa líder de su rama en el norte del Perú.
                  </Typography>
                </ThemeProvider>
                <br/>
                <ThemeProvider theme={theme}>
                  <Typography variant="h6" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#0C0C0C',fontSize: '1.0rem',
                  '@media (min-width:300px)': {
                    fontSize: '1.2rem',
                  },
                  [theme.breakpoints.up('md')]: {
                    fontSize: '2.0rem',
                  }}}>Es importante destacar que Alberto Gonzáles Lescano, es de procedencia provinciana(Contumazá-Cajamarca), de escasos recursos, sus primeros estudios relacionados a la avicultura los realizo en la Granja Escuela “David León” en su tierra natal, los amplios conocimientos que ha adquirido a lo largo de 50 años, se nutren de la disciplina en el trabajo cotidiano, su capacidad para adquirir nuevos conocimientos, su contacto amable y justo con sus trabajadores y la facilidad para adaptarse al avance de la tecnología. También hay que destacar su profunda vocación social y amor por su tierra a la que siempre visita.
                  </Typography>
                </ThemeProvider>
                <br/>
              </Cell>
              <Cell minWidthPx={100}></Cell>
            </Row>
          </Table>
          </div>

      
          <Footer/>
          
      </div>
  )}
}

export default About;