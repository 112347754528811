import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import About from '../About';
import Contact from '../Contact';
import Subsidiary from '../Subsidiary';
import Header from '../Header';
import Vision from '../Vision';
import Mision from '../Mision';
//Añadir más páginas
import * as ROUTES from '../../constants/routes';

const horizontalSeparados = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  margin: 'auto',
  justifyContent: 'space-between',
  alignItems: 'center'
};

const horizontalJuntos = {
  display: 'flex',
  margin: '0px !important',
  justifyContent: 'none !important',
  alignSelf: 'flex-end !important'
};

const App = () => (
  <Router>
    <div>
      <div style={horizontalSeparados}>
        <Header />
        <Navigation />
      </div>
      <Routes>
        <Route path={ROUTES.LANDING} element={<LandingPage />} />
        <Route path={ROUTES.ABOUT} element={<About />} />
        <Route path={ROUTES.MISION} element={<Mision />} />
        <Route path={ROUTES.VISION} element={<Vision />} />
        <Route path={ROUTES.CONTACT} element={<Contact />} />
        <Route path={ROUTES.SUBSIDIARY} element={<Subsidiary />} />
      </Routes>
    </div>
  </Router>
);

export default App;
