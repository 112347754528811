import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
//import FacebookIcon from '@mui/icons-material/Facebook';
//import InstagramIcon from '@mui/icons-material/Instagram';
//import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { SocialIcon } from 'react-social-icons'
import { browserHistory, Router, Route } from 'react-router';

export class SocialNetwork extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){

  }
  
  gotoInstagram(){
    
    }
  render() {
    return (
        <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
            <a href="https://es-la.facebook.com/AvicolaLescano1960" className="button">
                <SocialIcon network="facebook" style={{fill: "#9F2914", height: "0.3em", width: "0.3em"}} />
          </a>
            <a href="https://www.instagram.com/Avicola.Lescano.Peru" className="button">
                <SocialIcon network="instagram" style={{ fill: "#9F2914", height: "0.3rem", width: "0.3rem"}} />
          </a>
            <a href="https://api.whatsapp.com/send?phone=51950807536" className="button">
                <SocialIcon network="whatsapp" style={{ fill: "#9F2914", height: "0.3rem", width: "0.3rem"}} />
          </a>
      </div>
  )}
}

export default SocialNetwork;
