import React, { useState, Component, useReducer } from "react";
import Preview from '../Preview/index.js'
import '../../fonts/homemade.css';
import {
  useTheme,
  createMuiTheme,
  ThemeProvider
} from "@mui/material/styles";
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography'
//import FacebookIcon from '@mui/icons-material/Facebook';

import Footer from '../Footer/index.js';
import emailjs from 'emailjs-com';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

/*
const Landing = () => (
  <div>
  </div>
);*/

const theme = createMuiTheme({
  palette: {
    primary:{
      main: '#9F2914'
    }
  }
});

theme.typography.h3 = {
  color: '#9F2914',
  fontFamily: 'Homemade Apple',
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};

theme.typography.h4 = {
  color: '#9F2914',
  fontFamily: 'Roboto',
  fontSize: '0.5rem',
  '@media (min-width:100px)': {
    fontSize: '0.6rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.0rem',
  },
};

theme.typography.h6 = {
  color: '#FFFFFF',
  fontFamily: 'Roboto',
  fontSize: '0.6rem',
  '@media (min-width:100px)': {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.1rem',
  },
};


export default class Landing extends React.Component {
  
  constructor(props) {
    super(props);
    let wHeight = window.innerHeight;
    window.scrollY = 1;
    this.state = {
        width: 0, height: 0,
        nombre : '',
        email : '',
        empresa : '',
        mensaje : '',
        draggedOverCol: 0
      };
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      this.handleChange = this.handleInputChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
    }

    handleInputChange(event) {
      const target = event.target;
      const defaultValue = target.type === 'checkbox' ? target.checked : target.defaultValue;
      const name = target.name;
      
      this.setState({
        [name]: defaultValue
      });
      console.log('Change detected. State updated' + name + ' = ' + defaultValue);
    }

    handleSubmit(event) {
      alert('A form was submitted: ' + this.state.name + ' // ' + this.state.email);
      event.preventDefault();
    }

  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  
  render() {

    function enviarMensaje(e){

      e.preventDefault();
      if(e.target.nombre.value.trim() === "" || e.target.email.value.trim() === "" || e.target.empresa.value.trim() === "" || e.target.mensaje.value.trim() === ""){
        alert("Por favor, llene todos los campos");
        return;
      }
    emailjs.sendForm('service_wds0epo','template_s0id68k',e.target,'o5xQL82DZhY6JCA1v').then((result) => {
      console.log(result);
    }).catch(err => console.log(err));
    
    }

    return (
      <div>
        <br/>
        <Preview />
        <br/>
          <br/>
          <br/>
          <div style={{textAlign:'center'}}>
            <div style={{display: 'inline-block'}}>
              <ThemeProvider theme={theme}>
                <Typography variant="h3">¿Quienes Somos?</Typography>
              </ThemeProvider>
              <p  style={{display: 'inline-block'}} >Avícola Lescano, empresa especializada en la producción de huevos,<br/>inició sus actividades en el año 1960 en la ciudad de Contumazá<br/>(Cajamarca)</p>
              <br/>
              <br/>
              <Link className={"btn btn-submit"} to={ROUTES.ABOUT}>VER MÁS </Link>
              <br/>
              <br/>
              <br/>
              <ThemeProvider theme={theme}>
                <Typography variant="h3">Sucursales</Typography>
              </ThemeProvider>
            </div>
          </div>
          <div style={{textAlign:'center'}}>
            <div style={{display: 'flex',flexWrap: 'wrap',flexDirection: 'row', justifyContent: 'center', alignItems: 'auto',alignContent: 'start',marginLeft:'-15%'}}>
                <div style={{flex: '0 1 auto',margin: '25px'}} >
                  <img src={"https://firebasestorage.googleapis.com/v0/b/lescanoweb.appspot.com/o/18692-crop-u288.jpg?alt=media&token=5759e12d-82ef-4c4c-874e-ed4a4cd59c21"}/>
                </div>
                <div style={{display: 'inline-block'}}>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <ThemeProvider theme={theme}>
                  <Typography variant="h4" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#9F2914',fontSize: '1.0rem',
                    '@media (min-width:300px)': {
                      fontSize: '1.2rem',
                    },
                    [theme.breakpoints.up('md')]: {
                      fontSize: '2.0rem',
                    }}}>LA LIBERTAD - TRUJILLO</Typography>
                  </ThemeProvider>
                  <br/>
                  <div style={{ justifyContent: 'left'}}></div>
                  <Link className={"btn btn-submit"} to={ROUTES.SUBSIDIARY}>VER MÁS</Link>
                  <br/>
                  <br/>
                </div>
            </div>
            
          </div>
          <br/>
          <div id={'contactanos'}>
          <div style={{textAlign:'center'}}>
              <ThemeProvider theme={theme}>
                <Typography variant="h3">Contáctanos</Typography>
              </ThemeProvider>
          </div>
          <br/>
          <div style={{display: 'flex',flexWrap: 'wrap',flexDirection: 'row', justifyContent: 'center', alignItems: 'auto',alignContent: 'start'}}>
                <div style={{ display: 'inline-block',marginRight:'2%'}}>
                  <form onSubmit={enviarMensaje}>
                  <input type="text" defaultValue={this.state.nombre} id="nombre" style={{width: '20rem'}}  placeholder="Nombre" onChange={e => this.setState({[e.target.id]: e.target.defaultValue})} name="nombre" required />
                  <br/>
                  <br/>
                  <input type="email" defaultValue={this.state.email} id="email" style={{width: '20rem'}}  placeholder="Email" onChange={e => this.setState({[e.target.id]: e.target.defaultValue})} name="email" required />
                  <br/>
                  <br/>
                  <input type="text" defaultValue={this.state.empresa} id="empresa" style={{width: '20rem'}}  placeholder="Empresa" onChange={e => this.setState({[e.target.id]: e.target.defaultValue})} name="empresa" required />
                  <br/>
                  <br/>
                  <input type="multiline" defaultValue={this.state.mensaje} id="mensaje" style={{width: '20rem'}}  placeholder="Mensaje" onChange={e => this.setState({[e.target.id]: e.target.defaultValue})} name="mensaje" required />
                  <br/>
                  <br/>
                  <button className={"btn btn-submit"} style={{width: '150px', height: '40px'}} type="submit">Enviar</button>
                  
                  </form>
                </div>
                <div style={{display: 'inline-block',marginLeft:'2%',width:'25%'}}>
                <iframe className="actAsDiv" style={{width:'100%',height:'100%'}} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;q=Av.%20Arriaga%20%23800%20-%20Chicama%20Pueblo&amp;aq=0&amp;ie=UTF8&amp;t=m&amp;z=16&amp;iwloc=A&amp;output=embed"></iframe>
                </div>    
          </div>
          <br/>
          <br/>
          <div style={{display: 'flex',  flexWrap: 'wrap',  flexDirection: 'row',  justifyContent: 'center',  alignItems: 'center',  alignContent: 'start'}}>
            <div style={{flex: '0 0 auto', margin: '20px'}} >
              <ThemeProvider theme={theme}>
                <Typography variant="h6" style={{ color: '#9F2914',fontSize: '1.0rem',
                '@media (min-width:300px)': {
                  fontSize: '1.2rem',
                },
                [theme.breakpoints.up('md')]: {
                  fontSize: '2.0rem',
                }}}>Contactanos:
              </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <Typography variant="h6" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#0C0C0C',fontSize: '1.0rem',
                '@media (min-width:300px)': {
                  fontSize: '1.2rem',
                },
                [theme.breakpoints.up('md')]: {
                  fontSize: '2.0rem',
                }}}>
                  <pre>
                  978 505 508   ana.flores@avicolalescano.com<br/>
                  950 807 536   shirley.roncal@avicolalescano.com<br/>
                  999 867 461   miguel.tantalean@avicolalescano.com<br/>
                </pre>
              </Typography>
              </ThemeProvider>
            </div>
             
          </div>
          <div style={{textAlign:'center'}}>
              <ThemeProvider theme={theme}>
                <Typography variant="h6" style={{ color: '#9F2914',fontSize: '1.0rem',
                '@media (min-width:300px)': {
                  fontSize: '1.2rem',
                },
                [theme.breakpoints.up('md')]: {
                  fontSize: '2.0rem',
                }}}>Ubícanos:
              </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <Typography variant="h6" style={{fontFamily: "Roboto", fontStyle:"Thin", color: '#0C0C0C',fontSize: '1.0rem',
                '@media (min-width:300px)': {
                  fontSize: '1.2rem',
                },
                [theme.breakpoints.up('md')]: {
                  fontSize: '2.0rem',
                }}}>-Av. Arriaga #800 - Chicama Pueblo / Trujillo<br/>
              </Typography>
              </ThemeProvider>
          </div>
          <br/>
          <br/>
          </div>
                  
            <Footer/>
      </div>
  )}
}