import React, { Component } from 'react';
import Preview from '../Preview/index.js'
import { withRouter } from 'react-router-dom';

import {
  useTheme,
  createMuiTheme,
  ThemeProvider
} from "@mui/material/styles";

import '../../fonts/homemade.css';
import Typography from '@mui/material/Typography';
import { Table, Row, Cell } from 'react-responsive-table';

import Footer from '../Footer/index.js';


const theme = createMuiTheme({
  palette: {
    primary:{
      main: '#9F2914'
    }
  }
});

theme.typography.h3 = {
  color: '#9F2914',
  fontFamily: 'Homemade Apple',
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};

theme.typography.h6 = {
  color: '#FFFFFF',
  fontFamily: 'Roboto',
  fontSize: '0.6rem',
  '@media (min-width:100px)': {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.1rem',
  },
};

export class Mision extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }


  render() {
    return (
      <div>
        <div style={{textAlign:'center'}}>
          <ThemeProvider theme={theme}>
            <Typography variant="h3">¿Quienes Somos?</Typography>
          </ThemeProvider>
        </div>
        <br/>
        <img src={'https://firebasestorage.googleapis.com/v0/b/lescanoweb.appspot.com/o/mision.png?alt=media&token=36f896f1-5345-4cc8-8a3c-4efb8ffe7db9'} style={{width:'100%'}}/>
        <br/>
        

      
        <Footer/>
          
      </div>
  )}
}

export default Mision;